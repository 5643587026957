import { errorHandler, setHeaders } from "../helpers";
import axios from "axios";
import { BACKEND_URL } from "../constants";

export const SET_RIDERS_PAYMENT_LIST = "SET_RIDERS_PAYMENT_LIST";
export const SET_ADD_RIDERS_PAYMENT_LIST = "SET_ADD_RIDERS_PAYMENT_LIST";
export const SET_DELETE_RIDERS_PAYMENT_LIST = "SET_DELETE_RIDERS_PAYMENT_LIST";
export const SET_IS_LOADING_RIDERS_PAYMENT_LIST =
  "SET_IS_LOADING_RIDERS_PAYMENT_LIST";
export const RESET_RIDERS_PAYMENT_LIST = "RESET_RIDERS_PAYMENT_LIST";

export const setRidersPaymentList = (payments) => (dispatch) => {
  dispatch({
    type: SET_RIDERS_PAYMENT_LIST,
    payload: payments,
  });
};

export const addRidersPaymentList = (payment) => (dispatch) => {
  dispatch({
    type: SET_ADD_RIDERS_PAYMENT_LIST,
    payload: payment,
  });
};

export const deleteRidersPaymentList = (payment) => (dispatch) => {
  dispatch({
    type: SET_DELETE_RIDERS_PAYMENT_LIST,
    payload: payment,
  });
};

export const setIsLoadingRidersPaymentList = (trueOrFalse) => (dispatch) => {
  dispatch({
    type: SET_IS_LOADING_RIDERS_PAYMENT_LIST,
    payload: trueOrFalse,
  });
};

export const resetRidersPaymentList = () => ({
  type: RESET_RIDERS_PAYMENT_LIST,
});

export const fetchRidersPaymentList = () => (dispatch, getState) => {
  dispatch(setIsLoadingRidersPaymentList(true));
  const { user } = getState();
  axios
    .get(BACKEND_URL + "/riderswallet/all", setHeaders(user.token))
    .then((res) => {
      dispatch(setIsLoadingRidersPaymentList(false));
      dispatch(setRidersPaymentList(res.data.transactions));
    })
    .catch((error) => {
      dispatch(setIsLoadingRidersPaymentList(false));
      errorHandler(error);
    });
};
