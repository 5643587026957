// assets
import { ShoppingOutlined } from "@ant-design/icons";

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: "configGroup",
  type: "group",
  title: "Config",
  children: [
    {
      id: "sms",
      title: "SMS Config",
      type: "item",
      url: "/dashboard/sms",
      icon: ShoppingOutlined,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
