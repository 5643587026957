import { errorHandler, setHeaders } from "../helpers";
import axios from "axios";
import { BACKEND_URL } from "../constants";

export const SET_AGENTS_PAYMENT_LIST = "SET_AGENTS_PAYMENT_LIST";
export const SET_ADD_AGENTS_PAYMENT_LIST = "SET_ADD_AGENTS_PAYMENT_LIST";
export const SET_DELETE_AGENTS_PAYMENT_LIST = "SET_DELETE_AGENTS_PAYMENT_LIST";
export const SET_IS_LOADING_AGENTS_PAYMENT_LIST =
  "SET_IS_LOADING_AGENTS_PAYMENT_LIST";
export const RESET_AGENTS_PAYMENT_LIST = "RESET_AGENTS_PAYMENT_LIST";

export const setAgentsPaymentList = (payments) => (dispatch) => {
  dispatch({
    type: SET_AGENTS_PAYMENT_LIST,
    payload: payments,
  });
};

export const addAgentsPaymentList = (payment) => (dispatch) => {
  dispatch({
    type: SET_ADD_AGENTS_PAYMENT_LIST,
    payload: payment,
  });
};

export const deleteAgentsPaymentList = (payment) => (dispatch) => {
  dispatch({
    type: SET_DELETE_AGENTS_PAYMENT_LIST,
    payload: payment,
  });
};

export const setIsLoadingAgentsPaymentList = (trueOrFalse) => (dispatch) => {
  dispatch({
    type: SET_IS_LOADING_AGENTS_PAYMENT_LIST,
    payload: trueOrFalse,
  });
};

export const resetAgentsPaymentList = () => ({
  type: RESET_AGENTS_PAYMENT_LIST,
});

export const fetchAgentsPaymentList = () => (dispatch, getState) => {
  dispatch(setIsLoadingAgentsPaymentList(true));
  const { user } = getState();
  axios
    .get(BACKEND_URL + "/agentswallet/all", setHeaders(user.token))
    .then((res) => {
      dispatch(setIsLoadingAgentsPaymentList(false));
      dispatch(setAgentsPaymentList(res.data.transactions));
    })
    .catch((error) => {
      dispatch(setIsLoadingAgentsPaymentList(false));
      errorHandler(error);
    });
};
