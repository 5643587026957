// project import
import products from "./products";
import markets from "./markets";
import dashboard from "./dashboard";
import sales from "./sales";
import fees from "./fees";
import dishes from "./dishes";
import users from "./users";
import transactions from "./transactions";
import payments from "./payments";
import config from "./config";

const menuItems = {
  items: [
    dashboard,
    markets,
    products,
    payments,
    sales,
    fees,
    dishes,
    users,
    transactions,
    config,
  ],
};

export default menuItems;
